import React from "react"
import { navigate } from "gatsby"
import { useSelector, useDispatch } from "react-redux"
import { useCookies } from 'react-cookie';
import SEO from "../components/seo/seo"
import Layout from "../components/layout/layout"
import AppBar from "../components/appBar/appBar"
import MainSection from "../components/mainSection/mainSection"
import SecondarySection from "../components/secondarySection/secondarySection"
import PageSection from "../components/pageSection/pageSection"
import Card from "../components/card/card"
import LaunchForm from "../components/launchForm/launchForm"
import OptOut from "../components/optOut/optOut"
import IFrame from "../components/iFrame/iFrame"
import Modal from "../components/modal/modal"

import * as ACTIONS from "../state/actions"

const tcPdf = <IFrame file="../TC.pdf" title="Términos de uso"/>;
const pcPdf = <IFrame file="../PC.pdf" title="Política de privacidad"/>;
// const footerDesc = "";

const Index = () => {
    const appState = useSelector(state => state.appReducer);
    const dispatch = useDispatch();

    const [cookies, setCookie] = useCookies(['consent']);

    const launchFormHandler = () => {
        window.scroll({ top: 0, left: 0, behavior: "smooth" });

        const modal = {
            display: true,
            content: <LaunchForm lang={appState.lang} title="¡Gracias por tu interés! Estamos apunto de lanzar 🚀" subtitle="Solicita tu invitación y serás de los primeros en saber en cuanto estemos listos." amplitude={appState.amplitude} amplitudeClickEvent="PreSignUpCreated"/>
        };

        dispatch(ACTIONS.showModal(modal))
    };

    const termsUseHandler = () => {
        window.scroll({ top: 0, left: 0, behavior: "smooth" });
        dispatch(ACTIONS.showModal({display: true, content: tcPdf }))
    };

    const privacyPolicyHandler = () => {
        window.scroll({ top: 0, left: 0, behavior: "smooth" });
        dispatch(ACTIONS.showModal({display: true, content: pcPdf }))
    };

    const cookieHandler = () => {
        setCookie("consent", "dismiss", { path: '/' });
    };

    const goFinancingHandler = () => {
        navigate("/financiamiento/")
    };

    const closeModal = () => dispatch(ACTIONS.hideModal());

    return (
        <Layout lang={appState.lang} privacyPolicyAction={privacyPolicyHandler} termsUseHandlerAction={termsUseHandler}>
            <SEO lang={appState.lang} title="La cuenta empresarial digital EVVA que hace crecer tu dinero y 100% en línea." description="PyMEs y Startups, Freelancers dile adiós al papeleo y largas colas en sucursal. Con EVVA solicita tu cuenta empresarial digital que genera rendimientos diarios, 100% en línea."/>
            {appState.modal.display && <Modal content={appState.modal.content} closeModal={closeModal}/> }
            {!cookies.consent &&
            <OptOut text="Utilizamos cookies para garantizar que tengas la mejor experiencia en nuestro sitio web." privacyPolicyAction={privacyPolicyHandler} privacyPolicyLabel="Ver Política de privacidad" cookieAction={cookieHandler}/>
            }
            <MainSection title="EVVA, la cuenta digital empresarial que hace crecer tu dinero." description="<h3>PyME, Startup, Freelancer, dile adiós al papeleo y largas colas en sucursal. Solicita tu cuenta empresarial digital que genera rendimientos diarios, 100% en línea.</h3>" position="left" image="hero-home-v3.png" actionLabel="Solicita tu invitación" action={launchFormHandler} amplitude={appState.amplitude} amplitudeClickEvent="ClickPreSignUp">
                <AppBar pathname={appState.path}/>
            </MainSection>
            <PageSection>
                <div style={{marginTop: "-120px", zIndex: "1"}}>
                    <Card
                        subtitle="Obtén financiamiento para tu negocio"
                        description="<p>Hemos unido fuerzas con Uniclick by Unifin, líder en el mercado de financiamiento, que ha impulsado a miles de PyMEs en México.</p>"
                        descriptionColor="#363636"
                        image="search-icon.svg"
                        imageWidth="240px"
                        imageHeight="160px"
                        actionLabel="Saber mas"
                        action={goFinancingHandler}
                        actionVariant="outlined"
                        bgColor="#fff"
                        horizontal
                        padding
                        shadow
                    />
                </div>
            </PageSection>
            <SecondarySection
                title="Juntos crecemos"
                shortDescription="De emprendedores para emprendedores. Somos gente como tú que entiende tus necesidades. Nuestro objetivo final es que te enfoques en lo que realmente importa: tu empresa."
                position="center"
                image="evva-together.jpg"
                actionLabel="Solicita tu invitación"
                action={launchFormHandler}
                amplitude={appState.amplitude}
                amplitudeClickEvent="ClickPreSignUp"
            />
        </Layout>
    )
};

export default Index